<template>
    <div class="contact" id="contact" ref="contact">
        <div class="contact-in">
            <div class="contact__information">
                <div class="circle-out">
                    <div class="circle-holder">
                        <div class="out-circle">
                            <span class="circle"></span>
                        </div>
                        <div class="out-circle-1">
                            <span class="circle"></span>
                        </div>
                        <div class="out-circle-2">
                            <span class="circle"></span>
                        </div>
                        <div class="out-circle-3">
                            <span class="circle"></span>
                        </div>
                    </div>
                </div>
                <div :class="['contact-hold', {'animate': anim}]">
                    <h2>Contact</h2>
                    <a href="mailto:office@pupinpark.com">office@pupinpark.com</a>
                    <div class="contact__social">
                        <a href="https://www.instagram.com/PupinPark" class="mr-6" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']"/></a>
                        <a href="https://www.facebook.com/pupinpark/" target="_blank"><font-awesome-icon :icon="['fab', 'facebook-f']"/></a>
                    </div>
                    <h3>Subscribe to our newsletter</h3>
                    <form action="mailto:office@pupinpark.com" method="post" enctype="text/plain">
                        <input type="email" name="mail" placeholder="Email Address">
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
            <img :class="['contact-image-left', {'animate': anim}]" src="../../../assets/dizalica-1.png" alt="">
            <img :class="['contact-image-right', {'animate': anim}]" src="../../../assets/dizalica-2.png" alt="">
            <div class="bottom"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact',
    components: {
    },
    computed: {
    },
    data() {
        return {
            data: this.$root.$data,
            anim: false,
            isMobile: false
        };
    },
    mounted() {
        document.addEventListener("scroll", this.checkIfInView);
    },
    methods: {
        checkIfInView() {

            let top = document.getElementsByTagName("html")[0].scrollTop ? document.getElementsByTagName("html")[0].scrollTop : document.getElementsByTagName("body")[0].scrollTop;

            let ref = this.$refs.contact.offsetTop;
            let refH = this.$refs.contact.offsetHeight;

            if (top >= ref-350) {
                this.anim = true;
            } else {
                this.anim = false;
            }
        }
    }
}
</script>
