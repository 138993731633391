import Vue from 'vue';
import router from './router';
import VueResource from 'vue-resource';
import BootstrapVue from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Data from './Store';
import Methods from './Methods';

import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

Vue.use(Methods);
Vue.use(VueResource);
library.add(fas);
library.add(far);
library.add(fab);

Vue.component('font-awesome-icon', FontAwesomeIcon);

require('./style.scss');

new Vue({
  router,
  data: Data,
  created() {
  },
  render: h => h(App),
}).$mount('#app')