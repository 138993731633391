<template>
    <div class="invest" id="invest" ref="dev">
        <div class="line-top"></div>
        <div class="container">
            <div class="col-12 border why">
                <h2 class="page-title">
                    <span>WHY</span>INVEST
                </h2>
                <div class="row">
                    <div class="col-12 col-md-3">
                        <img src="../../../assets/1.png" alt="">
                    </div>
                    <div class="col-12 col-md-9 cen">
                        <p class="title">SERBIA-SYNONYM FOR HIGHLY QUALIFIED LABOR</p>
                        <p>
                            <b>With a unique combination of high-quality, wide availability and cost effectiveness, the Serbian labor force is widely regarded as a strong business performance driver.</b> For decades, Serbia fostered extensive relationships with leading economies. A list of blue-chip companies maintaining strong ties with local partners is topped by Siemens, Huawei, Bosch, Schneider Electric, Fiat Chrysler Automobiles, IKEA, and many others. Throughout years of cooperation, Serbian workers have received specific know-how and adopted advanced technology applications and rigorous quality control standards. Having vast experience in manufacturing and management, local staff requires minimum training to adopt cutting-edge technologies and assembly processes.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 border why">
                <h2 class="page-title">
                    <span>WHY</span>INVEST
                </h2>
                <div class="row">
                    <div class="col-12 col-md-3">
                        <img src="../../../assets/2.png" alt="">
                    </div>
                    <div class="col-12 col-md-9 cen">
                        <p class="title">SERBIA’S HIGHLY COMPETITIVE TAX RATES AND LOW OPERATING COSTS</p>
                        <p>
                            </b>Serbia’s tax regime is highly conducive to doing business. The corporate profit tax is among the lowest in Europe, while the Value Added Tax is among the most competitive in Central and Eastern Europe</b>. The VAT rates are as follows: The standard VAT rate - 20% (for most taxable supplies); The lower VAT rate - 10% (for basic food stuffs, daily newspapers, utilities, etc.). Corporate profit tax is paid at the uniform rate of 15%. Non-residents are taxed only based on their income generated in Serbia. Average salaries in Serbia ensure cost-effective operating. Total costs for employers stand at merely 50% of the level in EU countries from Eastern Europe. Social insurance charges and Salary Tax amount to roughly 65% of the net salary but the tax burden for employers can be reduced through a variety of financial and tax incentives
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Invest',
    components: {
    },
    computed: {
    },
    data() {
        return {
            data: this.$root.$data,
            anim: false
        };
    },
    mounted() {
        document.addEventListener("scroll", this.checkIfInView);
    },
    methods: {
    }
}
</script>
