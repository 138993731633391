<template>
  <div id="app" class="container-fluid m-0 p-0">
    <div :class="['app', {'anim-body': addAnim}]" id="inside">
      <heade></heade>
      <router-view/>
    </div>
  </div>
</template>

<script>

import Header from './components/Header.vue'

export default {
  name: 'app',
  components: {
    'heade': Header
  },
  data() {
    return {
      addAnim: false,
      pageHeight: 0,
      scrollPass: true,
    }
  },
  mounted() {

    this.addAnim = true;
    this.pageHeight = window.innerHeight;

  },
  methods: {
  },
  watch: {
    $route (to, from){
        this.addAnim = false;
        let self = this;
        this.$nextTick(() => {self.addAnim = true;});
    },
  }
}
</script>

<style>
</style>
