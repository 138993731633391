<template>
    <div class="development" id="dev" ref="dev">
        <div class="line-top"></div>
        <hooper ref="carousel" @slide="currentSlide" :wheelControl="false" :transition="600" >
            <slide>
                <div class="container">
                    <div class="col-12 btline">
                        <h2 class="page-title">
                            <span>DEVELOPMENT</span>PLAN
                        </h2>
                        <div class="row border">
                            <p class="subtitle mr-5">
                                    The project will be developed in three phases. Phase I is called the starting works, Phase II is settled for the expansion works and Phase III is defined as the future development works.
                                </p>
                            <div class="col-12 col-md-4">
                                <img class="dev-picture" src="../../../assets/dev1.png" alt="">
                            </div>
                            <div class="col-12 col-md-8 pb-5">
                                <p class="big dev-text">Phase 1</p>
                                <p class="mb-5 mr-5">
                                    This phase is focused on parts of the International Commercial Center and the Industrial Park, including such functions as commercial service, warehousing, processing, manufacturing (food production will be the priority industry), logistics and administrative service (“one-stop” park service center) and public works. This phase will involve 58.9 hectares, including International Commercial Center, 40ha in the Industrial Park, 0.8ha of supporting commerce and 10.1ha of roads and greening facilities. The High-Tech industry is also under full consideration in this phase according to the need of the market.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </slide>
            <slide>
                <div class="container">
                    <div class="col-12 btline">
                        <h2 class="page-title">
                            <span>DEVELOPMENT</span>PLAN
                        </h2>
                        <div class="row border">
                            <p class="subtitle mr-5">
                                    The project will be developed in three phases. Phase I is called the starting works, Phase II is settled for the expansion works and Phase III is defined as the future development works.
                                </p>
                            <div class="col-12 col-md-4">
                                <img class="dev-picture" src="../../../assets/dev2.png" alt="">
                            </div>
                            <div class="col-12 col-md-8">
                                <p class="big dev-text mb-5">Phase 2</p>
                                <p class="mb-5">
                                    <b>This phase will be focused on part of the Industrial Park.</b>
                                    It will cover <b>117.2</b> hectares, including:
                                </p>
                                <p class="ha mt-5">98.63ha <span>in the Industrial Park,</span></p>
                                <p class="ha">1.37ha <span>of supporting commerce.</span></p>
                                <p class="ha mb-5">17.2ha <span>of roads and greening facilities.</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </slide>
            <slide>
                <div class="container">
                    <div class="col-12 btline">
                        <h2 class="page-title">
                            <span>DEVELOPMENT</span>PLAN
                        </h2>
                        <div class="row border">
                            <p class="subtitle">
                                    The project will be developed in three phases. Phase I is called the starting works, Phase II is settled for the expansion works and Phase III is defined as the future development works.
                                </p>
                            <div class="col-12 col-md-6">
                                <img class="dev-picture" src="../../../assets/dev3.png" alt="">
                            </div>
                            <div class="col-12 col-md-6">
                                <p class="big dev-text mb-5">Phase 3</p>
                                <p>
                                    <b>This phase will be focused on the remaining part of the Industrial Park and on the High-Tech Park.</b>
                                    It will cover 143.9 hectares, including:
                                </p>
                                <p class="ha mt-5">108.8ha <span>in the Industrial Park</span></p>
                                <p class="ha">20 ha <span>in the High-Tech Park</span></p>
                                <p class="ha">1.4ha <span>of supporting commerce.</span></p>
                                <p class="ha mb-5 ">13.7ha <span>of roads and greening facilities.</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </slide>
        </hooper>
        <img v-if="showRight" src="../../../assets/right.png" alt="" class="right" @click="slideNext()">
        <img v-if="showLeft" src="../../../assets/left.png" alt="" class="left"  @click="slidePrev()">
    </div>
</template>

<script>

import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: 'Development',
    components: {
        Hooper,
        Slide
    },
    data() {
        return {
            data: this.$root.$data,
            anim: false,
            showLeft: true,
            showRight: false,
        };
    },
    mounted() {
    },
    methods: {
        currentSlide(e) {
            if (e.currentSlide===0) {
                this.showLeft = false;
                this.showRight = true;
            } else if (e.currentSlide===2) {
                this.showLeft = true;
                this.showRight = false;
            } else {
                this.showLeft = true;
                this.showRight = true;
            }
        },
        slidePrev() {
            this.$refs.carousel.slidePrev();
        },
        slideNext() {
            this.$refs.carousel.slideNext();
        },
    }
}
</script>
