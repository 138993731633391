<template>
    <div class="park" id="park">
        <div class="container center">
            <div class="line-top"></div>
            <hooper ref="carousel" @slide="currentSlide" :transition="600" >
                <slide>
                    <div>
                        <h2 class="page-title park-title">
                            <span>THE</span>PARK
                        </h2>
                        <div class="col-12 border">
                            <div class="minh">
                                <p><b>The Park is planned as a modern comprehensive industrial park that includes an Industrial Park, an International Commercial Center and a High-Tech Park with  ancillary services</b>. The idea is to integrate city development and industrial development together to boost the development of both the city and Serbia-China Industrial Park Mihajlo Pupin. Existing resources should be fully used, an elaborate industrial layout should be designed with a focus on competitive industries and key industries to gather more people, and favorable industrial, transportation and ecological conditions should be created to attract enterprises, businesses and customers in order to realize a substantial increase in regional GDP, create considerable work opportunities and thus lead future development of the area and the city.</p><br>
                            </div>
                            <img src="../../../assets/picture.jpg" alt="">
                        </div>
                    </div>
                </slide>
                <slide>
                    <div>
                        <h2 class="page-title park-title">
                            <span>FUNCTIONAL</span>AREAS
                        </h2>
                        <div class="col-12 border">
                            <div class="minh">
                                <p><b>Serbia-China Industrial Park shall be planned to three functional areas: International Commercial Center, Industrial Park and High-Tech Park.</b></br>
                                <b>1) International Commercial Center:</b> International Commercial Center shall cover 8 ha in land in total. Arranged on the southeast of the integrated park, it shall cover general commodity market, logistics and warehousing center, exhibition center, hotels, restaurants, retail stores, etc. arranged internally.<br>
                                <b>2) Industrial Park:</b> Industrial Park shall cover 247 ha in land in total. Plants, warehouses, office buildings, supporting apartments and service areas concerned shall be arranged internally.<br>
                                <b>3) High-Tech Park:</b> High-Tech Park shall cover 20 ha in land in total, including scientific and technological incubation building, enterprise office building, supporting service facilities, etc.
                                </p><br>
                            </div>
                            <img src="../../../assets/picture.jpg" alt="">
                        </div>
                    </div>
                </slide>
                <slide>
                    <div>
                        <h2 class="page-title park-title">
                            <span>DESIGN</span>GREEN
                        </h2>
                        <div class="col-12 border">
                            <div class="minh">
                                <p><b>The project is seriously committed to green energy in infrastructure, ‘green design’, as well as the focus on sustainable development and reduced environmental footprint.</b> Such industrial parks are commonly referred to as ‘eco-industrial parks’ since proximity of the companies and faster and simpler communication, transport and cooperation - energy consumption and impact to the environment are drastically reduced. Eco-industrial Park is also a term for projects that use eco-friendly materials and ‘green’ design in their infrastructure.</p><br>
                            </div>
                            <img src="../../../assets/picture.jpg" alt="">
                        </div>
                    </div>
                </slide>
            </hooper>
            <div :class="['line-bottom', `line-bottom-${currentSlideIs}`]"></div>
            <div class="line-right"></div>
            <div class="line-left"></div>
        </div>
    </div>
</template>

<script>

import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: 'Park',
    components: {
        Hooper,
        Slide
    },
    computed: {
    },
    data() {
        return {
            data: this.$root.$data,
            currentSlideIs: 0
        };
    },
    mounted() {
    },
    beforeDestroy () {
    },
    methods: {
        currentSlide(e) {
            this.currentSlideIs = e.currentSlide;
        },
    },

}
</script>
