<template>
    <div class="operating" id="op" ref="op">
        <div class="row p-0 m-0 text-center operating-inside">
            <div class="col p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col p-0">
                        <div class="circle-holder">
                            <span class="circle"></span>
                            <span class="circle"></span>
                            <span class="circle"></span>
                            <span class="circle"></span>
                            </div>
                        <h2>Dreams set sail from here</h2>
                        <h3>Operating model</h3>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center text m-0 p-0">
                    <div class="col w-fix">
                        <div class="row p-0 m-0">
                            <div :class="['col', 'col-md-6', 'col-sm-6', 'col-xs-6', 'plat', 'text-left', 'pr-6', 'platforms', {'animate': anim===true}]">
                                <h6>Platforms</h6>
                                <span>Lead</span>
                            </div>
                            <div :class="['col-6','col-sm-6', 'col-xs-6', 'ent', 'text-right', 'pl-6', 'enterprices', {'animate': anim===true}]">
                                <h6>Enterprices</h6>
                                <span>Grow</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center m-0 p-0">
                    <div class="col">
                        <div class="tree">
                            <img :class="['tree-1', 'position-absolute', 'b-0', {'animate': anim===true}]" src="../../../assets/stablo.png" alt="">
                            <img :class="['tree-2', {'animate': anim===true}]" src="../../../assets/grane2.png" alt="">
                            <img :class="['tree-3', 'position-absolute', {'animate': anim===true}]" src="../../../assets/krosnja.png" alt="">
                        </div>
                    </div>
                </div>
                <div :class="['row', 'justify-content-center', 'align-items-center', 'pb-6', 'm-0', 'p-0', 'government', {'animate': anim===true}]">
                    <div class="col">
                        <h4>Government</h4>
                        <h5>Provide</h5>
                    </div>
                </div>
                <div class="row m-0 p-0 b-fix">
                    <div class="col m-0 p-0 position-relative">
                        <div class="bottom"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Operating',
    components: {
    },
    computed: {
    },
    data() {
        return {
            data: this.$root.$data,
            anim: false
        };
    },
    mounted() {
        document.addEventListener("scroll", this.checkIfInView);
    },
    methods: {
        checkIfInView() {
            
            let ref = this.$refs.op.offsetTop;
            let refH = this.$refs.op.offsetHeight;

            let top = document.getElementsByTagName("html")[0].scrollTop ? document.getElementsByTagName("html")[0].scrollTop : document.getElementsByTagName("body")[0].scrollTop;

            if (top >= ref-100) {
                this.anim = true;
            } else {
                this.anim = false;
            }
        }
    }
}
</script>
