<template>
    <div class="feature" id="about" ref="about">
        <div class="feature__box text-center"> <!-- feature start -->
            <div class="container">
                <div :class="['row', 'p-0', 'm-0', 'container-feature', {'animate': anim===true}]">
                   <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="ftitle">
                            <p>PUPIN <br> Location</p>
                        </div>
                        <div class="fimage">
                            <img src="../../../assets/i1.png" alt="">
                        </div>
                        <div class="ftext">
                            <p>The project is predominantly designed as an industrial park and will be located at the extension part of Pupin Bridge, Borča. By conjoining the PUPIN PARK complex to Pupin Bridge an impression of unity is created, making it easy to memorize both the name and location, so the objects (bridge and the zone) become part of the same story, making the entire part of the town into a city of science.</p>
                        </div>
                   </div>
                   <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="ftitle">
                            <p>The authority <br> of a scientist</p>
                        </div>
                        <div class="fimage">
                            <img src="../../../assets/i2.png" alt="">
                        </div>
                        <div class="ftext">
                            <p>Mihajlo Pupin is one of the greatest Serbian and American scientists, with globally recognizable name and achievements. His name is associated with a large number of inventions and scientific discoveries. Therefore, it is more than adequate for the project of science-technology Park in Borča, as it endows whole complex with higher authority. Pupin is also attractive name for young scientists, IT professionals, people working in telecommunications, but it is also widely recognized - with exclusively positive connotations.</p>
                        </div>
                   </div>
                   <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="ftitle">The investor: <br> <span>The investor:</span></div>
                        <div class="fimage">
                            <img src="../../../assets/i3.png" alt="">
                        </div>
                        <div class="ftext">
                            <p>The bridge is also result of a Chinese-Serbian partnership and is therefore commonly known as the 'Chinese Bridge'. Since Pupin Park is also the result of Chinese-Serbian partnership, the local public will make the association.</p>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="ftitle">
                            <p>PARK</p>
                        </div>
                        <div class="fimage">
                            <img src="../../../assets/i4.png" alt="">
                        </div>
                        <div class="ftext">
                            <p>Industrial Park is a generic name for all projects that involve presence of a variety of industries in one place. Even though this project will also include a commercial zone, residential district and a high-tech sector, the predominant orientation is towards industrial park emphasis, making the word PARK a symbol for the type of the project on the one hand, and for green energy on the other.</p>
                        </div>
                   </div>
                   <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="ftitle">
                            <p>ECO-INDUSTRIAL-PARK</p>
                        </div>
                        <div class="fimage">
                            <img src="../../../assets/i5.png" alt="">
                        </div>
                        <div class="ftext">
                            <p>The project can be commended for its serious commitment to green energy in infrastructure, 'green design', as well as the focus on sustainable development and reduced environmental footprint. Such industrial parks are commonly referred to as 'eco-industrial parks' since proximity of the companies and faster and simpler communication, transport and cooperation - energy consumption and impact to the environment are drastically reduced. Eco-industrial Park is also a term for projects that use eco-friendly materials and 'green' design in their infrastructure.</p>
                        </div>
                   </div>
                   <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="ftitle">
                            <p>COMERCIAL ZONE</p>
                        </div>
                        <div class="fimage">
                            <img src="../../../assets/i1.png" alt="">
                        </div>
                        <div class="ftext">
                            <p>The second most important focus of the project is the commercial district, which is why we highlight it in the signature.<br><br> ZONE is a term that implies the size of a project that encompasses an entire part of the city, a small city in itself.</p>
                        </div>
                    </div> 
                 </div>
            </div>
        </div> <!-- feature end -->
        <div class="bottom"></div>
    </div>
</template>

<script>
export default {
    name: 'Feature',
    components: {
    },
    computed: {
    },
    data() {
        return {
            data: this.$root.$data,
            anim: false
        };
    },
    mounted() {
        document.addEventListener("scroll", this.checkIfInView);
    },
    methods: {
        checkIfInView() {

            let top = document.getElementsByTagName("html")[0].scrollTop ? document.getElementsByTagName("html")[0].scrollTop : document.getElementsByTagName("body")[0].scrollTop;

            let ref = this.$refs.about.offsetTop;
            let refH = this.$refs.about.offsetHeight;

            if (top >= ref-100) {
                this.anim = true;
            } else {
                this.anim = false;
            }
        }
    }
}
</script>
