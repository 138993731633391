import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/pages/Home'


Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    }
  ]
})
