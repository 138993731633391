<template>
    <div class="hero p-0 m-0" id="hero">
        <div class="video-component" v-show="playVideo">
            <div class="mask-video" @click="playVideo=false"></div>
            <img class="close" src="../../../assets/close.png" @click="playVideo=false" alt="">
            <div class="video-holder">
                <video ref="heroVideo" :class="['hero-vid']" controls playsinline preload="auto" src="/pupinparkdug.mp4" allowfullscreen="allowfullscreen"></video>
            </div>
        </div>
        <video ref="video" @click="playVid()" class="m-0 p-0 background-video" preload="auto" playsinline loop muted autoplay src="/pupinparkkratak.mp4"></video>
        <!--font-awesome-icon class="play" :icon="['fas', 'play']"/-->
        <div ref="titleWpar" class="title-wrap">
            <h1 class="title">WE<span>CREATE</span></h1>
            <h2 class="subtitle">the best innovative and <br/> entrepreneurial ecosystem</h2>
        </div>
        <div class="line"></div>
    </div>
</template>

<script>
export default {
    name: 'Hero',
    components: {
    },
    computed: {
    },
    data() {
        return {
            data: this.$root.$data,
            playVideo: false
        };
    },
    mounted() {
        let self = this;
        this.$refs.video.addEventListener('timeupdate', function(){

            if(this.currentTime > 10) {
                self.$refs.titleWpar.style.display = 'none';
            }

            if (this.currentTime < 10) {
                self.$refs.titleWpar.style.display = 'block';
            }

        });
    },
    methods: {
        playVid() {
            this.playVideo=true;
            this.$refs.heroVideo.play();
        }
    },
    watch: {
        playVideo: function() {
        if (this.playVideo)
            this.$refs.heroVideo.play();
            else
                this.$refs.heroVideo.pause();
        }
    }
}
</script>
