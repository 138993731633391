<template>
    <div class="ecosystem" id="eco" ref="eco">
        <div class="row p-0 m-0 h-10">
            <div class="col p-0 m-0">
                <div class="top"></div>
            </div>
        </div>
        <div class="row p-0 m-0 h-80">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h1 class="text-center">Creating the best innovative and entrepreneurial ecosystem</h1>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center content-eco h-80 m-0 p-0">
                    <div class="col text-center">
                        <span class="circ"></span>
                        <span class="circ"></span>
                        <span class="circ"></span>
                        <span class="circ"></span>
                        <span class="circ"></span>
                        <span class="circ"></span>
                        <span class="circ"></span>
                        <span class="circ"></span>
                        <span class="circ"></span>
                        <img src="../../../assets/cent.png" alt="">
                        <span :class="['info', {'animate': anim===true}]">
                            <img src="../../../assets/hat.png" alt="">
                            <div>First-class universities</div>
                        </span>
                        <span :class="['info', {'animate': anim===true}]">
                            <img src="../../../assets/kruna.png" alt="">
                            <div>Leading enterprises</div>
                        </span>
                        <span :class="['info', {'animate': anim===true}]">
                            <img src="../../../assets/sijalica.png" alt="">
                            <div>Entrepreneurship policy</div>
                        </span>
                        <span :class="['info', {'animate': anim===true}]">
                            <img src="../../../assets/grupa.png" alt="">
                            <div>Entrepreneurship culture</div>
                        </span>
                        <span :class="['info', {'animate': anim===true}]">
                            <img src="../../../assets/priroda.png" alt="">
                            <div>Living environment</div>
                        </span>
                        <span :class="['info', {'animate': anim===true}]">
                            <img src="../../../assets/zastava.png" alt="">
                            <div>Government services</div>
                        </span>
                        <span :class="['info', {'animate': anim===true}]">
                            <img src="../../../assets/novac.png" alt="">
                            <div>Capital market</div>
                        </span>
                        <span :class="['info', {'animate': anim===true}]">
                            <img src="../../../assets/social.png" alt="">
                            <div>Social space</div>
                        </span>
                        <span :class="['info', {'animate': anim===true}]">
                            <img src="../../../assets/talent.png" alt="">
                            <div>Talent resources</div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-0 m-0 h-10 position-absolute bottom-out">
            <div class="col p-0 m-0">
                <div class="bottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Ecosystem',
    components: {
    },
    computed: {
    },
    data() {
        return {
            data: this.$root.$data,
            anim: false,
        };
    },
    mounted() {
        document.addEventListener("scroll", this.checkIfInView);
    },
    methods: {
        checkIfInView() {
            
            let ref = this.$refs.eco.offsetTop;
            let refH = this.$refs.eco.offsetHeight;

            let top = document.getElementsByTagName("html")[0].scrollTop ? document.getElementsByTagName("html")[0].scrollTop : document.getElementsByTagName("body")[0].scrollTop;

            if (top >= ref-100) {
                this.anim = true;
            } else {
                this.anim = false;
            }
        }
    }
}
</script>
