<template>
    <div id="home" class="home">
        <hero></hero>
        <park></park>
        <maps></maps>
        <development></development>
        <invest></invest>
        <contact></contact>
    </div>
</template>

<style lang="scss" rel="stylesheet/scss">
    @import "/../../scss/home";
</style>

<script>

import Hero from './parts/Hero.vue'
import Ecosystem from './parts/Ecosystem.vue'
import Development from './parts/Development.vue'
import Operating from './parts/Operating.vue'
import Feature from './parts/Feature.vue'
import Contact from './parts/Contact.vue'
import Park from './parts/Park.vue'
import Maps from './parts/Map.vue'
import Invest from './parts/Invest.vue'

export default {
    name: 'Home',
    components: {
        'hero': Hero,
        'park': Park,
        'maps': Maps,
        'invest': Invest,
        'ecosystem': Ecosystem,
        'Operating': Operating,
        'development': Development,
        'feature': Feature,
        'contact': Contact,
    },
    computed: {
    },
    data() {
        return {
            data: this.$root.$data,
        };
    },
    methods:{
    }
}
</script>
