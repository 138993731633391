<template>
    <div :class="['header', {'hide': hideHeader===true, 'show': show}]">
      <div class="container">
        <nav class="navbar navbar-expand-lg">
          <button class="navbar-toggler" type="button" @click="show=!show">
            <span class="navbar-toggler-icon"><font-awesome-icon class="hanburger" :icon="['fas', 'bars']"/></span>
          </button>
          <router-link class="navbar-brand p-0" to="#hero">
            <img class="image-logo" src="../assets/logo-1.png" alt="">
          </router-link>
          <div :class="['collapse', 'navbar-collapse', 'justify-content-md-center', {'show': show}]">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link class="nav-link" to="#home">Home</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="#park">The Park</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="#op">Functional areas</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="#op">Green design</router-link>
              </li>
              <li class="nav-item fix-w"></li>
              <li class="nav-item mf">
                <router-link class="nav-link mf" to="#map">Location</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="#invest">Why invest?</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="#dev">Development plan</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="#contact">Contact</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
</template>
<script>
export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
      data: this.$root.$data,
      show: false,
      hideHeader: false,
      lastPos: 0
    }
  },
  computed: {
  },
  mounted() {
    let self = this;
    document.addEventListener('scroll', function(){

      if(document.documentElement.scrollTop > self.lastPos) {
        self.hideHeader = true;
      } else {
        self.hideHeader = false;
      }

      self.lastPos = document.documentElement.scrollTop <= 0 ? 0 : document.documentElement.scrollTop;

    });
  },
  methods: {
  }
}
</script>