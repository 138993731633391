<template>
    <div class="map" id="map">
        <div class="container">
            <div class="row" @mouseenter="inside=1" @mouseleave="inside=0">
                <div class="line-top"></div>
                <div class="col-12 col-md-5 text-right">
                    <p><b>Serbia-China Industrial Park is located to the northwest of Belgrade city on the Northern bank of the Danube River, which separates the park and the new urban area of Belgrade.</b> It is close to Mihajlo Pupin Bridge in the south, where trunk roads connected to the bridge allow rapid transport to airport, railway station and the intercity expressway network. Project adjoins a cargo terminal under planning in the northwest. According to planning, this project will cover an area of 320 hectares and will be a comprehensive park that includes an Industrial Park, an International Commercial Center and a High-Tech Park.</p>
                </div>
                <div class="col-12 col-md-7 marmobile">
                    <h2 class="page-title">LOCATION</h2>
                    <img id="imageMap" src="/map1.jpg" alt="">
                </div>
                <div class="line-bottom"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Maps',
    components: {
    },
    computed: {
    },
    data() {
        return {
            data: this.$root.$data,
        };
    },
    mounted() {
    },
    beforeDestroy () {
    },
    methods: {
    }
}
</script>
